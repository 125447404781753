<template>
    <div class="loginComponentWrap insetCenter">
        <AuthorizeUserComponent
                @loginSuccess="resolveAfterSigning"
                @signupSuccess="resolveAfterSigning"
                :mission-type="missionType"
                :showCloseBtn="true"
        ></AuthorizeUserComponent>
    </div>
</template>

<script>
    import AuthorizeUserComponent from "../../components/account/AuthorizeUserComponent";
    export default {
        name: 'HomePage-single',
        components: {
            AuthorizeUserComponent,
        },
        data() {
            return {
                missionType: 'login',
            }
        },
        methods:{
            resolveAfterSigning(){
                if(this.$route.query.redirect) {
                    this.jump(this.$route.query.redirect)
                }
                else{
                    this.$router.push('/index');
                }
            },
            jump(url){
                if(url.startsWith("http")){
                    window.location.href= url;
                }
                this.$router.push(url);
            }
        },
    }
</script>